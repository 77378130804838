import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading = new Subject<boolean>;
  private hideGuid? = '';
  constructor(private spinner: NgxSpinnerService) { }

  hide(hideGuid?: string): void {
    if (!this.hideGuid) {
      this.spinner.hide();
      this.isLoading.next(false);
    } else if (hideGuid === this.hideGuid) {
      this.hideGuid = '';
      this.spinner.hide();
      this.isLoading.next(false);
    }
  }

  show(hideGuid?: string): void {
    this.spinner.show();
    this.isLoading.next(true);
    if (hideGuid) {
      this.hideGuid = hideGuid;
    }
  }

  get uniqueSpinId() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }
}
