<form [formGroup]="searchForm">
  <mat-toolbar class="tool-bar">
    <mat-form-field class="tdoe-grow">
      <mat-select formControlName="academicYear" class="academic-year">
        <mat-option *ngFor="let ay of academicYears" [value]="ay.academicYearId">{{ ay.displayLabel }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-button-toggle-group formControlName="searchType">
      <mat-button-toggle value="all">All Courses</mat-button-toggle>
      <mat-button-toggle value="recent">Recently Changed</mat-button-toggle>
    </mat-button-toggle-group>
  </mat-toolbar>

  <div class="upper-emphasis" style="display:flex;justify-content:flex-end;">
    <span>Last published:</span>
    <span class="emphasis-value" [innerText]="lastPublishDate"></span>
  </div>

  <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="tabChanged($event)">
    <mat-tab class="section" label="Basic Search">
      <tdoe-card subtitle="Search courses" class="section">
        <ng-container content>
          <div style="display:flex;padding-right:24px;margin-top:20px">
            <mat-form-field class="tdoe-grow">
              <mat-label>Query</mat-label>
              <input matInput class="form-control" placeholder="Search" formControlName="query">
              <mat-hint>Enter your search term and click <em>Search</em></mat-hint>
            </mat-form-field>
            <button style="height:50px;margin-left:20px" class="btn-primary" mat-raised-button
              [disabled]="searchForm.invalid" (click)="search(true)">
              <mat-icon style="margin-right:12px">search</mat-icon>
              <span>Search</span>
            </button>
          </div>
        </ng-container>
      </tdoe-card>
    </mat-tab>
    <mat-tab label="Advanced Search">
      <tdoe-card subtitle="Criteria">
        <ng-container content>
          <div class="criteria-wrap" *ngFor="let term of courseQuery.advancedSearchTerms; let i = index">
            <span *ngIf="i > 0" class="title-label">
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              AND
            </span>
            <span *ngIf="i === 0" class="title-label">
              Search For
            </span>
            <mat-form-field>
              <mat-select [(ngModel)]="term.fieldName" (selectionChange)="manageRow(i)"
                [ngModelOptions]="{standalone: true}">
                <mat-option value="Name">Name</mat-option>
                <mat-option value="Description">Description</mat-option>
                <mat-option value="Type">Type</mat-option>
                <mat-option value="Code">Code</mat-option>
                <mat-option value="Grades">Grades</mat-option>
                <mat-option value="Endorsements">Endorsements</mat-option>
                <mat-option value="Attributes">Attributes</mat-option>
                <mat-option value="PreviousCourseCode">Previous Code</mat-option>
                <mat-option value="ContentArea">Content Area</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field id="selAttribute" *ngIf="term.fieldName == 'Attributes'">
              <mat-select [(ngModel)]="term.attributeCode"
                (selectionChange)="setDefaultAttributeSearchTerm(term, term.attributeCode)"
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let attribute of attributes; let i = index" [value]="attribute.systemCode">
                  {{attribute.displayLabel}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span class="title-label">that</span>
            <mat-form-field id="selContains" *ngIf="showContains(term.fieldName, term.attributeCode)">
              <mat-select [(ngModel)]="term.operator" [ngModelOptions]="{standalone: true}">
                <mat-option value="Contains">Contains</mat-option>
                <mat-option value="Does Not Contain">Does Not Contain</mat-option>
                <mat-option value="Starts with">Starts With</mat-option>
                <mat-option value="Ends with">Ends With</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field id="selIncludes" *ngIf="showIncludes(term.fieldName, term.attributeCode)">
              <mat-select [(ngModel)]="term.operator" [ngModelOptions]="{standalone: true}">
                <mat-option value="Include">Includes</mat-option>
                <mat-option value="Exclude">Excludes</mat-option>
              </mat-select>
            </mat-form-field>
            &nbsp;
            <mat-form-field *ngIf="showContains(term.fieldName, term.attributeCode)">
              <input matInput #input id="advancedSearchText" name="advancedSearchText" [(ngModel)]="term.searchTerm[0]"
                (keyup.enter)="search(true)" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
            <mat-form-field *ngIf="term.fieldName == 'Grades'">
              <mat-select [(ngModel)]="term.searchTerm[0]" value="grades[0].systemCode"
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let grade of grades; let i = index" [value]="grade.systemCode">
                  {{grade.displayLabel}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="term.fieldName == 'ContentArea'">
              <mat-select [(ngModel)]="term.searchTerm[0]" value="contentAreas[0].systemCode"
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let contentArea of contentAreas; let i = index" [value]="contentArea.systemCode">
                  {{contentArea.displayLabel}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="term.fieldName == 'Type'">
              <mat-select [(ngModel)]="term.searchTerm[0]" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let type of courseTypes; let i = index" [value]="type.systemCode">
                  {{type.displayLabel}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="term.fieldName == 'Endorsements'">
              <mat-select [(ngModel)]="term.searchTerm[0]" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let endorsement of endorsements; let i = index" [value]="endorsement.systemCode">
                  ({{endorsement.systemCode}}){{endorsement.displayLabel}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div id="divCheckbox"
              *ngIf="term.fieldName == 'Attributes' && getAttributeControlType(term.attributeCode) == 'checkbox'"
              class="input-1X inline">
              <mat-checkbox id="chkAttribute" name="chkAttribute" labelPosition="before"
                [checked]="term.searchTerm[0] === 'true'" (change)="flipCheckBoxAttribute(term)">
                {{getAttributeDisplayLabel(term.attributeCode)}}
              </mat-checkbox>
            </div>
            <mat-form-field
              *ngIf="term.fieldName == 'Attributes' && getAttributeControlType(term.attributeCode) == 'multiselect'">
              <mat-label>{{ getAttributeDisplayLabel(term.attributeCode) }}</mat-label>
              <mat-select multiple id="selAttrValues" [(ngModel)]="term.searchTerm"
                [ngModelOptions]="{standalone: true}">
                <mat-option
                  *ngFor="let opt of getAttribute(term.attributeCode)?.properties?.multiselectOptions; let i = index;"
                  [value]="opt.value">
                  {{opt.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              *ngIf="term.fieldName == 'Attributes' && getAttributeControlType(term.attributeCode) == 'dropdown'">
              <mat-select [(ngModel)]="term.searchTerm[0]" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let value of getAttributeValues(term.attributeCode); let i = index"
                  [value]="value.value.toString()">
                  {{value.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button *ngIf="i>0" mat-mini-fab color="warn" (click)="removeRow(i)">
              <mat-icon>remove</mat-icon>
            </button>
          </div>
          <div style="display:flex;justify-content:flex-start;">
            <button mat-mini-fab color="accent" (click)="addAdvancedRow()">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div style="display:flex;justify-content:flex-end">
            <button style="height:50px;" class="btn-primary" mat-raised-button [disabled]="searchForm.invalid"
              (click)="search(true)">
              <mat-icon style="margin-right:12px">search</mat-icon>
              <span>Search</span>
            </button>
          </div>
        </ng-container>
      </tdoe-card>
      <tdoe-card subtitle="Additional options">
        <ng-container content>
          <div class="d-flex" style="justify-content: start;">
            <mat-checkbox [(ngModel)]="courseQuery.isRetired" style="font-size:x-large"
              [ngModelOptions]="{standalone: true}">Display Courses By Retirement
              Status</mat-checkbox>
            <span class="tdoe-grow"></span>
            <mat-form-field>
              <mat-label>Order by</mat-label>
              <mat-select [(ngModel)]="courseQuery.pagingOptions.sortColumns[0].columnName"
                [ngModelOptions]="{standalone: true}">
                <mat-option value="Description">Description</mat-option>
                <mat-option value="Name">Name</mat-option>
                <mat-option value="Type">Type</mat-option>
                <mat-option value="Code">Code</mat-option>
                <mat-option value="retireDate">Date</mat-option>
                <mat-option value="PreviousCourseCode">Previous Code</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Direction</mat-label>
              <mat-select [(ngModel)]="courseQuery.pagingOptions.sortColumns[0].direction"
                [ngModelOptions]="{standalone: true}">
                <mat-option value="ASC">Ascending</mat-option>
                <mat-option value="DESC">Descending</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
      </tdoe-card>
    </mat-tab>
  </mat-tab-group>

  <tdoe-card *ngIf="results">
    <ng-container header>
      <div class="d-flex" style="justify-content:space-between">
        <h3>Search Results</h3>
        <button style="margin-top:6px;margin-right:32px" [matMenuTriggerFor]="exportMenu" class="btn-primary"
          mat-raised-button>
          <span style="margin-right:12px;">Export</span>
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #exportMenu="matMenu">
          <button mat-menu-item (click)="export('excel')">Excel Export</button>
          <button mat-menu-item (click)="export('csv')">CSV Export</button>
          <button mat-menu-item (click)="export('json')">JSON Export</button>
          <button mat-menu-item (click)="export('pipe')">Pipe Delimited Export</button>
          <button mat-menu-item (click)="export('report')">Course Change Report</button>
        </mat-menu>
      </div>
    </ng-container>
    <ng-container content>
      <table mat-table matSort class="mat-elevation-z8" [dataSource]="results.results ?? []"
        (matSortChange)="onSortChange($event)">

        <ng-container matColumnDef="courseCode">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by course code" *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let course"><span [innerHTML]="course.courseCode"></span></td>
        </ng-container>

        <ng-container matColumnDef="previousCourseCode">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by previous code" *matHeaderCellDef>Prev
            Code(s)</th>
          <td mat-cell *matCellDef="let course"><span [innerHTML]="course.previousCourseCode"></span></td>
        </ng-container>

        <ng-container matColumnDef="publishState">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by status" *matHeaderCellDef>Status</th>
          <td [class.course-retired]="isRetired(course)" mat-cell *matCellDef="let course"><span
              [innerHTML]="status(course)"></span></td>
        </ng-container>

        <ng-container matColumnDef="courseName">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by course name" *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let course">
            <a innerHTML="{{course.courseName}}&nbsp;{{course.retireDate ?'('+(course.retireDate | date:'shortDate')+')':''}}"
              [routerLink]="['/course', course.courseCode, course.courseId, currentYear]"
              [state]="{ course, savedState: { results, searchForm: searchForm.value, academicYears, lastPublishDate } }">
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="retireStatus">
          <th mat-header-cell  *matHeaderCellDef class="table-cell">Retire Status</th>
          <td mat-cell  *matCellDef="let course" class="table-cell"><span [innerHTML]="course.retireStatus"></span></td>
        </ng-container>

        <ng-container matColumnDef="courseType">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by course type" *matHeaderCellDef>Type</th>
          <td mat-cell  *matCellDef="let course"><span [innerHTML]="course.courseType"></span></td>
        </ng-container>

        <ng-container matColumnDef="contentArea">
          <th mat-header-cell mat-sort-header sortActionDescription="Sort by subject" *matHeaderCellDef>Subject</th>
          <td mat-cell  *matCellDef="let course"><span [innerHTML]="course.contentArea"></span></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr [title]="course.courseName" mat-row *matRowDef="let course; columns: displayedColumns;"
          class="course-element-row" [class.course-retired]="isRetired(course)"></tr>
      </table>
      <div style="display:flex;gap:12px;justify-content:space-between;">
        <span class="mat-elevation-z8 jumper" *ngIf="pages">
          <span style="margin-right:16px;">Go to page:</span>
          <mat-select style="width:100px" [value]="results.currentPage" (selectionChange)="goToPage($event)">
            <mat-option *ngFor="let page of pages" [innerText]="page" [value]="page"></mat-option>
          </mat-select>
        </span>
        <mat-paginator *ngIf="results.pageCount > 1" [pageSizeOptions]="pageSizeOptions" [length]="results.rowCount"
          [pageSize]="pageSize" [pageIndex]="results.currentPage - 1" [showFirstLastButtons]="true"
          class="mat-elevation-z8" (page)="paginationChanged($event)"></mat-paginator>
      </div>
    </ng-container>
  </tdoe-card>

</form>
